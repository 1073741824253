import clsx from "clsx";
import { Token } from "core/types";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { formatBalance } from "utils/balance";
import { useBalance } from "wagmi";

interface TokensListItemProps {
  token: Token;
  onTokenSelect: (token: Token) => void;
  isSelected: boolean;
}

const TokensListItem: React.FC<TokensListItemProps> = ({
  token,
  onTokenSelect,
  isSelected,
}) => {
  const { address } = useGlobalContext();

  const { data } = useBalance({
    address,
    token: token.address,
    enabled: !!address,
  });

  return (
    <div
      key={token.address}
      className={clsx(
        "flex w-auto cursor-pointer flex-row items-center gap-4 rounded-lg p-4 hover:bg-tertiary-100",
        isSelected && "cursor-not-allowed opacity-30"
      )}
      onClick={() => (isSelected ? undefined : onTokenSelect(token))}
    >
      <picture>
        <img
          className="h-10 w-10 rounded-full"
          src={token.logoURI}
          alt={token.symbol}
        />
      </picture>
      <div className="flex flex-grow flex-col">
        <div className="text-lg font-medium">{token.symbol}</div>
        <div className="flex flex-row justify-between text-sm">
          <span>{token.name}</span>
          <span>
            {formatBalance({ value: data?.value, decimals: data?.decimals })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TokensListItem;
