import { useMemo, useState } from "react";
import { TransactionHeader } from "src/_components/TransactionHeader";
import { useSwapContext } from "src/_contexts/swap/hooks";
import { useToggle } from "usehooks-ts";
import { Icon } from "_components/Icon";
import DialogWindow from "../Dialog";
import SwapConfirm from "../SwapConfirm";
import { SwapSummaryDetails } from "../SwapSummaryDetails";
import TokenSelector from "../TokenSelector";
import { MemoizedTransactionSettings } from "../TransactionSettings";
import { TransactionStatus } from "../TransactionStatus";
import { SwapButton } from "./button";

const Swap: React.FC = () => {
  const {
    token0,
    token0Balance,
    token0Value,
    setToken0,
    onToken0ValueChange,
    token1,
    token1Balance,
    token1Value,
    setToken1,
    onToken1ValueChange,
    reset,
    swapTransactionError,
    swapTransactionPending,
    swapTransactionSuccess,
    transactionHash,
    canRefresh,
    refresh,
    reverseTokens,
  } = useSwapContext();

  const [openTransactionSettings, _, setOpenTransactionSettings] = useToggle();

  const [openSwapWindow, setOpenSwapWindow] = useState<boolean>(false);

  const closeConfirmSwapWindow = () => {
    setOpenSwapWindow(false);
    reset();
  };

  const statusVariant = useMemo(() => {
    if (swapTransactionPending) return "pending";
    if (swapTransactionSuccess) return "success";
    if (swapTransactionError) return "error";
    return undefined;
  }, [swapTransactionPending, swapTransactionSuccess, swapTransactionError]);

  return (
    <div className="mx-auto flex max-w-md flex-col gap-4 rounded-2xl bg-primary-700 p-4 text-white sm:p-6">
      <MemoizedTransactionSettings
        isOpen={openTransactionSettings}
        onClose={() => setOpenTransactionSettings(false)}
      />
      <DialogWindow
        title="Confirm transaction"
        titleBackground={true}
        isOpen={openSwapWindow}
        onClose={() => closeConfirmSwapWindow()}
      >
        {!statusVariant && <SwapConfirm />}
        {statusVariant && (
          <TransactionStatus
            variant={statusVariant}
            transactionID={transactionHash}
            onClose={() => closeConfirmSwapWindow()}
          />
        )}
      </DialogWindow>
      <TransactionHeader
        title="Swap"
        icon="swap"
        canRefresh={canRefresh}
        openTransactionSettings={() => setOpenTransactionSettings(true)}
        refresh={() => refresh()}
      />
      <TokenSelector
        label="From"
        token={token0}
        onTokenSelect={(token) => setToken0(token.symbol)}
        tokenBalance={token0Balance}
        tokenValue={token0Value}
        onTokenValueChange={onToken0ValueChange}
        excludeToken={token1}
      />
      <div className="flex items-start justify-center">
        <div
          onClick={() => reverseTokens()}
          className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full border border-tertiary-100 bg-white"
        >
          <Icon
            icon="arrow-downward"
            className="text-primary-600 transition duration-300 ease-in-out hover:rotate-180"
          />
        </div>
      </div>
      <TokenSelector
        label="To"
        token={token1}
        onTokenSelect={(token) => setToken1(token.symbol)}
        tokenBalance={token1Balance}
        tokenValue={token1Value}
        onTokenValueChange={onToken1ValueChange}
        excludeToken={token0}
      />
      <SwapSummaryDetails textColor="light" />
      <div className="flex items-center justify-center">
        <SwapButton openSwapDialog={() => setOpenSwapWindow(true)} />
      </div>
    </div>
  );
};

export default Swap;
