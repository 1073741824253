import { Token } from "core/types";
import React from "react";

import { tokensFilter } from "./utils";

export const useFilterTokens = (
  tokens: Token[] | undefined,
  search: string | undefined
): Token[] | undefined => {
  const [tokensList, setTokenList] = React.useState<Token[] | undefined>(
    tokens
  );

  React.useEffect(() => {
    setTokenList(tokensFilter(tokens, search));
  }, [tokens, search]);

  return tokensList;
};
