import { Token } from "core/types";
import { useState } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import DialogWindow from "../Dialog";
import { useFilterTokens } from "./hooks";
import TokensListItem from "./Item";

interface TokensListProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onTokenSelect: (token: Token) => void;
  selectedToken?: Token;
  excludeToken?: Token;
}

const TokensList: React.FC<TokensListProps> = ({
  isOpen,
  setIsOpen,
  onTokenSelect,
  selectedToken,
  excludeToken,
}) => {
  const { tokens } = useGlobalContext();
  const [search, setSearch] = useState<string>("");
  const tokensList = useFilterTokens(tokens, search);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const tokenSelect = (token: Token) => {
    setSearch("");
    onTokenSelect(token);
  };

  const closeDialogWindow = () => {
    setSearch("");
    setIsOpen(false);
  };

  return (
    <DialogWindow isOpen={isOpen} onClose={closeDialogWindow}>
      <input
        placeholder="Search name or paste address"
        className="mb-4 w-full rounded-lg border-2 border-primary-600 p-4 focus:border-tertiary-200 focus:outline-none focus:ring"
        onChange={handleSearchChange}
      />
      <div className="flex max-h-[44vh] flex-col gap-4 overflow-scroll p-2">
        {tokensList?.map((token) => (
          <TokensListItem
            key={token.address}
            token={token}
            onTokenSelect={tokenSelect}
            isSelected={[
              selectedToken?.address,
              excludeToken?.address,
            ].includes(token.address)}
          />
        ))}
      </div>
    </DialogWindow>
  );
};

export default TokensList;
