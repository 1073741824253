import { memo } from "react";
import { NumericFormat } from "react-number-format";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { TransactionSettingsActions } from "src/_reducers/transaction-settings/types";
import { appConfig } from "../../configs/app";
import { Button } from "../Button";
import DialogWindow from "../Dialog";

interface TransactionSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

const TransactionSettings: React.FC<TransactionSettingsProps> = ({
  isOpen,
  onClose,
}) => {
  const { transactionSettings, setTransactionSettings } = useGlobalContext();

  const validateSlippage = (value: number | undefined) => {
    if (!value) return true;

    return (
      !!value &&
      value >= appConfig.localStorage.slippageTolerance.min &&
      value < appConfig.localStorage.slippageTolerance.max
    );
  };

  const validateDeadline = (value: number | undefined) => {
    if (!value) return true;

    return (
      !!value &&
      value >= appConfig.localStorage.transactionDeadline.min &&
      value < appConfig.localStorage.transactionDeadline.max
    );
  };

  const storeSlippage = (value: number | undefined) => {
    if (value) {
      setTransactionSettings({
        type: TransactionSettingsActions.SET_SLIPPAGE_TOLERANCE,
        payload: value,
      });
    }
  };

  const storeTimeout = (value: number | undefined) => {
    if (value) {
      setTransactionSettings({
        type: TransactionSettingsActions.SET_TRANSACTION_TIMEOUT,
        payload: value,
      });
    }
  };

  const resetSlippage = () => {
    if (
      transactionSettings.slippageTolerance !==
      appConfig.localStorage.slippageTolerance.default
    )
      setTransactionSettings({
        type: TransactionSettingsActions.RESET_SLIPPAGE_TOLERANCE,
      });
  };

  const resetTimeout = () => {
    if (
      transactionSettings.transactionTimeout !==
      appConfig.localStorage.transactionDeadline.default
    )
      setTransactionSettings({
        type: TransactionSettingsActions.RESET_TRANSACTION_TIMEOUT,
      });
  };

  return (
    <DialogWindow isOpen={isOpen} onClose={() => onClose()}>
      <div>
        <div className="p-2 text-sm text-primary-700">
          Slippage Tolerance (%)
        </div>
        <div className="flex flex-row items-center gap-4">
          <NumericFormat
            className="max-w-[176px] rounded-lg bg-tertiary-100 p-4 text-2xl font-medium focus:border-tertiary-200 focus:outline-none focus:ring"
            thousandSeparator
            allowNegative={false}
            value={transactionSettings.slippageTolerance}
            onValueChange={(values, _) => storeSlippage(values.floatValue)}
            allowLeadingZeros={false}
            valueIsNumericString={true}
            decimalScale={1}
            isAllowed={({ floatValue }) => validateSlippage(floatValue)}
          />
          <Button
            variant="primary-dark"
            onClick={() => resetSlippage()}
            contentWidth
          >
            Reset
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <div className="p-2 text-sm text-primary-700">
          Transaction Deadline (mins)
        </div>
        <div className="flex flex-row items-center gap-4">
          <NumericFormat
            className="max-w-[176px] rounded-lg bg-tertiary-100 p-4 text-2xl font-medium focus:border-tertiary-200 focus:outline-none focus:ring"
            thousandSeparator
            allowNegative={false}
            value={transactionSettings.transactionTimeout}
            onValueChange={(values, _) => storeTimeout(values.floatValue)}
            allowLeadingZeros={false}
            valueIsNumericString={true}
            decimalScale={0}
            isAllowed={({ floatValue }) => validateDeadline(floatValue)}
          />
          <Button
            variant="primary-dark"
            onClick={() => resetTimeout()}
            contentWidth
          >
            Reset
          </Button>
        </div>
      </div>
    </DialogWindow>
  );
};

export const MemoizedTransactionSettings = memo(TransactionSettings);
