import { AddressZero, Zero } from "@ethersproject/constants";
import { Hash } from "@wagmi/core";
import { ABIS } from "constants/abis";
import { BigNumber, utils } from "ethers";
import { useTransactionFee } from "hooks/gas";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { handleErrorMessage } from "utils/errors";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import {
  useAddressApprove,
  useInputValue,
  usePair,
  useReserves,
  useTokenBalance,
  useTokenQueryParams,
} from "_hooks";
import { useInterval } from "_hooks/interval";
import { useVisibilityChange } from "_hooks/visibility-change";
import { SwapContext } from "./context";
import { SwapContextProviderProps } from "./types";

export const SwapContextProvider: React.FC<SwapContextProviderProps> = ({
  children,
}) => {
  const {
    address,
    addresses,
    timestamp,
    refetchTimestamp,
    transactionSettings,
  } = useGlobalContext();

  const [isPageVisible, setIsPageVisible] = useState(true);
  const [activeInput, setActiveInput] = useState<"token0" | "token1">("token0");

  const { token0, token1, setToken0, setToken1, reverseTokens } =
    useTokenQueryParams();

  const [token0Value, token0ValueBN, __, setToken0Value] = useInputValue(
    "0",
    token0?.decimals
  );
  const [token1Value, token1ValueBN, token1MinValueBN, setToken1Value] =
    useInputValue("0", token1?.decimals);

  useEffect(() => {
    setToken0Value("0");
    setToken1Value("0");
  }, [token0, setToken0Value, token1, setToken1Value]);

  const [token0Balance, hasEnoughToken0Balance, refetchToken0Balance] =
    useTokenBalance({ token: token0?.address, value: token0ValueBN });
  const [token1Balance, _, refetchToken1Balance] = useTokenBalance({
    token: token1?.address,
    value: token1ValueBN,
  });

  const { pairAddress } = usePair({
    token0: token0?.address,
    token1: token1?.address,
  });

  const { liquidityTokenTotalSupply, canFetchLiquidity, token1Reserves } =
    useReserves({
      pairAddress,
      token0,
      token1,
    });

  const [isToken0Allowed, token0Approve, token0ApproveInProgress] =
    useAddressApprove({ token: token0?.address, value: token0ValueBN });

  const canRefresh = useMemo(
    () => Boolean(isPageVisible && address && token0ValueBN?.gt(Zero)),
    [isPageVisible, address, token0ValueBN]
  );

  const hasEnoughLiquidity = useMemo(
    () =>
      (liquidityTokenTotalSupply?.gt(Zero) ?? false) &&
      token1Reserves.gt(token1ValueBN),
    [liquidityTokenTotalSupply, token1Reserves, token1ValueBN]
  );

  const { isLoading: getAmountsOutIsLoading, refetch: refetchTokenAmounts } =
    useContractRead({
      address: addresses?.ROUTER ?? AddressZero,
      abi: ABIS.ROUTER,
      functionName: "getAmountsOut",
      enabled: false,
      args: [
        activeInput === "token0" ? token0ValueBN : token1ValueBN,
        activeInput === "token0"
          ? [token0?.address ?? AddressZero, token1?.address ?? AddressZero]
          : [token1?.address ?? AddressZero, token0?.address ?? AddressZero],
      ],
      onError: (error) => {
        handleErrorMessage(error);
        console.log("cryyyy");
      },
      onSuccess: (data) =>
        activeInput === "token0"
          ? setToken1Value(utils.formatUnits(data[1], token0?.decimals))
          : setToken0Value(utils.formatUnits(data[1], token1?.decimals)),
    });

  const refreshData = useCallback(async () => {
    await refetchTimestamp();
    await refetchToken0Balance();
    await refetchToken1Balance();
  }, [refetchTimestamp, refetchToken0Balance, refetchToken1Balance]);

  const _onToken0ValueChange = useCallback(
    async (value: string) => {
      setActiveInput("token0");
      if (canFetchLiquidity) setToken1Value("0");
      setToken0Value(value);
      await refreshData();
    },
    [canFetchLiquidity, refreshData, setToken0Value, setToken1Value]
  );

  const _onToken1ValueChange = useCallback(
    async (value: string) => {
      setActiveInput("token1");
      if (canFetchLiquidity) setToken0Value("0");
      setToken1Value(value);
      await refreshData();
    },
    [canFetchLiquidity, refreshData, setToken0Value, setToken1Value]
  );

  const onToken0ValueChange = useMemo(
    () => debounce(_onToken0ValueChange, 300),
    [_onToken0ValueChange]
  );

  const onToken1ValueChange = useMemo(
    () => debounce(_onToken1ValueChange, 300),
    [_onToken1ValueChange]
  );

  useEffect(() => {
    if (
      activeInput === "token0" &&
      !token0ValueBN.isZero() &&
      canFetchLiquidity
    ) {
      refetchTimestamp();
      refetchTokenAmounts();
    }
  }, [
    activeInput,
    canFetchLiquidity,
    refetchTimestamp,
    refetchTokenAmounts,
    token0ValueBN,
  ]);

  useEffect(() => {
    if (
      activeInput === "token1" &&
      !token1ValueBN.isZero() &&
      canFetchLiquidity
    ) {
      refetchTimestamp();
      refetchTokenAmounts();
    }
  }, [
    activeInput,
    canFetchLiquidity,
    refetchTimestamp,
    refetchTokenAmounts,
    token1ValueBN,
  ]);

  const transactionArgs: [BigNumber, BigNumber, [Hash, Hash], Hash, BigNumber] =
    useMemo(
      () => [
        token0ValueBN,
        token1MinValueBN ?? Zero,
        [token0?.address ?? AddressZero, token1?.address ?? AddressZero],
        address ?? AddressZero,
        BigNumber.from(timestamp + transactionSettings.transactionTimeout * 60),
      ],
      [
        token0ValueBN,
        token1MinValueBN,
        token0,
        token1,
        address,
        timestamp,
        transactionSettings.transactionTimeout,
      ]
    );

  const { config } = usePrepareContractWrite({
    address: addresses?.ROUTER ?? AddressZero,
    abi: ABIS.ROUTER,
    functionName: "swapExactTokensForTokens",
    enabled: Boolean(
      addresses?.ROUTER &&
        token0 &&
        token1 &&
        token0ValueBN?.gt(Zero) &&
        token1MinValueBN?.gt(Zero) &&
        address &&
        timestamp &&
        transactionSettings.transactionTimeout &&
        hasEnoughToken0Balance &&
        isToken0Allowed &&
        hasEnoughLiquidity
    ),
    args: transactionArgs,
    onError: (error) => handleErrorMessage(error),
  });

  const transactionFee = useTransactionFee(config?.request?.gasLimit);

  const { data, isLoading, isError, isSuccess, write, reset } =
    useContractWrite(config);

  const {
    isLoading: transactionIsLoading,
    isError: transactionIsError,
    isSuccess: transactionIsSuccess,
  } = useWaitForTransaction({
    hash: data?.hash,
    enabled: !!data,
    onSuccess: (data) => {
      if (data.status === 1) {
        setToken0Value("0");
        setToken1Value("0");
        refetchToken0Balance();
        refetchToken1Balance();
      }
    },
    onError: (error) => handleErrorMessage(error),
  });

  const swapTransactionPending = useMemo(
    () => isLoading || transactionIsLoading,
    [isLoading, transactionIsLoading]
  );
  const swapTransactionError = useMemo(
    () => isError || transactionIsError,
    [isError, transactionIsError]
  );
  const swapTransactionSuccess = useMemo(
    () => isSuccess || transactionIsSuccess,
    [isSuccess, transactionIsSuccess]
  );

  const isInProgress = useMemo(() => {
    return (
      getAmountsOutIsLoading ||
      token0ApproveInProgress ||
      swapTransactionPending
    );
  }, [getAmountsOutIsLoading, token0ApproveInProgress, swapTransactionPending]);

  const swap = () => {
    write && write();
  };

  useVisibilityChange({
    onHide: () => {
      setIsPageVisible(false);
    },
    onShow: async () => {
      setIsPageVisible(true);
      await refreshData();
    },
  });

  const { resetInterval } = useInterval(refreshData, canRefresh ? 10000 : null);

  const refresh = () => {
    resetInterval();
    refreshData();
  };

  return (
    <SwapContext.Provider
      value={{
        token0,
        token0Balance: token0Balance?.value,
        token0ValueBN,
        token0Value,
        isToken0Allowed,
        onToken0ValueChange,
        setToken0,
        token1,
        token1Balance: token1Balance?.value,
        token1Value,
        token1ValueBN,
        onToken1ValueChange,
        setToken1,
        token1MinValueBN,
        hasEnoughLiquidity,
        hasEnoughToken0Balance,
        token0Approve,
        swap,
        reset,
        transactionFee,
        transactionHash: data?.hash,
        isInProgress,
        swapTransactionPending,
        swapTransactionError,
        swapTransactionSuccess,
        canRefresh,
        refresh,
        reverseTokens,
      }}
    >
      {children}
    </SwapContext.Provider>
  );
};
