import { Token } from "core/types";
import { BigNumber } from "ethers";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { formatBalance } from "utils/balance";
import { Icon } from "_components/Icon";
import TokensList from "../TokensList";

interface TokenSelectorProps {
  label: string;
  token?: Token;
  tokenBalance?: BigNumber;
  tokenValue: string;
  onTokenSelect: (token: Token) => void;
  onTokenValueChange: (value: string) => void;
  excludeToken?: Token;
}

const TokenSelector: React.FC<TokenSelectorProps> = ({
  label,
  token,
  tokenBalance,
  tokenValue,
  onTokenSelect,
  onTokenValueChange,
  excludeToken,
}) => {
  const [isTokenListOpen, setIsTokenListOpen] = useState<boolean>(false);

  const handleTokenSelect = (token: Token) => {
    onTokenSelect(token);
    setIsTokenListOpen(false);
  };

  return (
    <div>
      <TokensList
        isOpen={isTokenListOpen}
        setIsOpen={setIsTokenListOpen}
        onTokenSelect={handleTokenSelect}
        selectedToken={token}
        excludeToken={excludeToken}
      />
      <div className="flex flex-row items-center justify-between pb-2 text-sm text-tertiary-100">
        <span>{label}</span>
        <span className="text-primary-400">
          Balance:{" "}
          {formatBalance({ value: tokenBalance, decimals: token?.decimals })}
        </span>
      </div>
      <div className="grid grid-cols-[1fr_auto] gap-1 rounded-lg bg-white p-4">
        <NumericFormat
          className="overflow-hidden bg-white text-4xl text-primary-700 focus-visible:outline-none"
          thousandSeparator
          allowNegative={false}
          onValueChange={async (values) =>
            await onTokenValueChange(values.value)
          }
          allowLeadingZeros={true}
          valueIsNumericString={true}
          decimalScale={6}
          inputMode="decimal"
          value={tokenValue}
          placeholder="0"
        />
        <div
          className="grid cursor-pointer grid-cols-[40px_80px] gap-2"
          onClick={() => setIsTokenListOpen(true)}
        >
          <picture>
            <img
              className="h-10 w-10 rounded-full"
              src={token?.logoURI}
              alt={token?.symbol}
            />
          </picture>
          <span className="flex items-center justify-between font-medium text-primary-700">
            {token?.symbol} <Icon icon="expand" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TokenSelector;
