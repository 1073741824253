import clsx from "clsx";
import { useSwapContext } from "src/_contexts/swap/hooks";
import { formatBalance } from "../../utils/balance";
import { SwapSummaryDetailsProps } from "./interfaces";

export const SwapSummaryDetails: React.FC<SwapSummaryDetailsProps> = ({
  textColor,
}) => {
  const { transactionFee, token1MinValueBN, token1 } = useSwapContext();

  return (
    <div
      className={clsx(
        "grid w-full grid-cols-2 gap-2 bg-transparent",
        textColor === "dark" ? "text-primary-700" : "text-white"
      )}
    >
      <div className="text-sm">Fee</div>
      <div className="inline-flex justify-end gap-2 text-right font-medium">
        {formatBalance({ value: transactionFee })} MATIC
      </div>
      <div className="text-sm">Minimum Received</div>
      <div className="text-right font-medium">
        {formatBalance({
          value: token1MinValueBN,
          decimals: token1?.decimals,
          tokenSymbol: token1?.symbol,
        })}
      </div>
    </div>
  );
};
