import { Token } from "core/types";
import { BigNumber } from "ethers";
import { formatBalance } from "src/utils/balance";
import { useSwapContext } from "src/_contexts/swap/hooks";
import { Icon } from "_components/Icon";
import { Button } from "../Button";
import { SwapSummaryDetails } from "../SwapSummaryDetails";

const Token: React.FC<{ token?: Token; value?: BigNumber }> = ({
  token,
  value,
}) => (
  <div className="flex items-center gap-2">
    <picture>
      <img
        className="h-6 w-6 rounded-full"
        src={token?.logoURI}
        alt={token?.symbol}
      />
    </picture>
    <span className="font-bold text-primary-700">
      {formatBalance({ value, decimals: token?.decimals })}
    </span>
    <span className="text-primary-700">{token?.symbol}</span>
  </div>
);

const SwapConfirm: React.FC = () => {
  const { token0, token0ValueBN, token1, token1ValueBN, swap } =
    useSwapContext();

  return (
    <div className="flex flex-col items-center justify-center gap-8 bg-white">
      <div className="flex w-full flex-col items-center justify-center gap-1 rounded-lg border border-primary-400 bg-tertiary-100 px-16 py-2">
        <Token token={token0} value={token0ValueBN} />
        <div className="flex items-start justify-center">
          <div className="flex h-12 w-12 items-center justify-center rounded-full border border-tertiary-100 bg-white">
            <Icon icon="arrow-downward" className="text-primary-600" />
          </div>
        </div>
        <Token token={token1} value={token1ValueBN} />
      </div>
      <SwapSummaryDetails textColor="dark" />
      <Button
        variant="primary-dark"
        onClick={() => (swap ? swap() : undefined)}
      >
        Confirm Swap
      </Button>
    </div>
  );
};

export default SwapConfirm;
