import { useGlobalContext } from "src/_contexts/global/hooks";
import { useSwapContext } from "src/_contexts/swap/hooks";
import { Button } from "../Button";

interface SwapButtonProps {
  openSwapDialog: () => void;
}

export const SwapButton: React.FC<SwapButtonProps> = ({ openSwapDialog }) => {
  const { address, setOpenWallet } = useGlobalContext();
  const {
    isInProgress,
    hasEnoughLiquidity,
    token0ValueBN,
    hasEnoughToken0Balance,
    isToken0Allowed,
    token0Approve,
    token0,
  } = useSwapContext();

  if (!address) {
    return (
      <Button
        variant="primary-light"
        disabled={isInProgress}
        onClick={() => setOpenWallet(true)}
      >
        Connect wallet
      </Button>
    );
  }

  if (token0ValueBN?.isZero()) {
    return (
      <Button variant="disabled" disabled={true}>
        Enter amount
      </Button>
    );
  }

  if (!hasEnoughToken0Balance) {
    return <Button variant="disabled">Insufficient balance</Button>;
  }

  if (!hasEnoughLiquidity) {
    return (
      <Button variant="disabled" disabled={!hasEnoughLiquidity}>
        Insufficient liquidity
      </Button>
    );
  }

  if (!isToken0Allowed) {
    return (
      <Button
        variant="primary-light"
        onClick={() =>
          !isInProgress && token0Approve ? token0Approve() : undefined
        }
        isLoading={isInProgress}
      >
        Allow to use {token0?.symbol}
      </Button>
    );
  }

  return (
    <Button
      variant="primary-light"
      isLoading={isInProgress}
      onClick={() => (!isInProgress ? openSwapDialog() : undefined)}
    >
      Swap
    </Button>
  );
};
