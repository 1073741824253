import { Token } from "core/types";

export const tokensFilter = (
  tokens: Token[] | undefined,
  tokenSearch: string | undefined
): Token[] | undefined => {
  if (!tokens || !tokenSearch || tokenSearch === "") {
    return tokens;
  }

  const lowerCaseSearch = tokenSearch.toLowerCase();

  return tokens.filter(
    (token) =>
      token.address.toLowerCase().includes(lowerCaseSearch) ||
      token.name.toLowerCase().includes(lowerCaseSearch) ||
      token.symbol.toLowerCase().includes(lowerCaseSearch)
  );
};
