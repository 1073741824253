import { Zero } from "@ethersproject/constants";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { appConfig } from "src/configs/app";
import { useFeeData } from "wagmi";

export const useTransactionFee = (gasLimit?: BigNumber) => {
  const [transactionFee, setTransactionFee] = useState<BigNumber>(Zero);

  const { data: feeData } = useFeeData({
    formatUnits: "gwei",
    enabled: !!gasLimit,
  });

  useEffect(() => {
    if (!gasLimit || !feeData?.gasPrice) {
      setTransactionFee(Zero);
    } else {
      setTransactionFee(
        gasLimit.mul(appConfig.gasLimitMultiplier).mul(feeData.gasPrice)
      );
    }
  }, [feeData?.gasPrice, gasLimit]);

  return transactionFee;
};
