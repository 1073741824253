import Swap from "components/Swap";
import { Layout } from "layouts/default";
import { NextPage, NextPageContext } from "next";
import { NextSeo } from "next-seo";
import { SwapContextProvider } from "src/_contexts/swap/provider";

const SwapPage: NextPage = () => {
  return (
    <Layout>
      <NextSeo title="Swap tokens" />
      <div className="mx-auto max-w-md">
        <SwapContextProvider>
          <Swap />
        </SwapContextProvider>
      </div>
    </Layout>
  );
};

SwapPage.getInitialProps = async (context: NextPageContext) => {
  return { token0: context.query.token0, token1: context.query.token1 };
};

export default SwapPage;
